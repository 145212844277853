import React, { useEffect, useState } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import swal from "sweetalert";

import { IStore } from "../../types/store";
import { IPipeline } from "../../types/type.pipeline";
import ShinyIframeView from "../../components/Views/ShinyIframeView";
import { TDispatch } from "../../types/action";
import { TExecution } from "../../types/type.execution";
import {
  getOutput,
  resetPipeline,
  runPipeline,
} from "../../actions/action.pipeline";
import history from "../../views/history";
import pipelineBasicParams from "../../reducers/pipeline-basic-params";
import config from "../../config";
import { isInputValid } from "../../actions/action.pipeline-input";
import { PIPELINE_NAME_MAX_LENGTH } from "../../utils/constants";
import { getOneBatch, setSelectedBatch } from "../../actions/action.batch";
import { getOneProject } from "../../actions/action.project";
import { IProfile } from "../../types/type.user";

interface IProps {
  select?: Array<string>;
}

const heatplot = 3;
const gating = 9;
const generateMeta = 13;
const beadNormalization = 16;
const tetdecon2021 = 17;
const manualTetdecon2021 = 18;
const debarcode = 19;
const scatterPlot = 20;
const highdimInteractive = 23;
const sequencingGating = 25;
const histogram = 26;
const tcrQuery = 27;
const stitchr = 28;
const curate = 33;
const map = 34;

const shinyUrls = {
  development: {
    // local
    "ap-southeast-1": {
      [heatplot]: "https://cytographer-dev.immunoscape.com/#/profile",
      [gating]: "https://cytographer-dev.immunoscape.com/#/help",
      [generateMeta]: "https://cytographer-dev.immunoscape.com/#/notification",
      [beadNormalization]: "https://cytographer-dev.immunoscape.com/#/help",
      [manualTetdecon2021]: "https://cytographer-dev.immunoscape.com/#/help",
      [debarcode]: "https://cytographer-dev.immunoscape.com/#/notification",
      [scatterPlot]: "https://cytographer-dev.immunoscape.com/#/notification",
      [highdimInteractive]:
        "https://cytographer-dev.immunoscape.com/#/notification",
      [sequencingGating]:
        "https://cytographer-dev.immunoscape.com/#/notification",
      [histogram]: "https://cytographer-dev.immunoscape.com/#/notification",
      [tcrQuery]: "https://cytographer-dev.immunoscape.com/#/notification",
      [stitchr]: "https://cytographer-dev.immunoscape.com/#/notification",
      [curate]: "https://cytographer-dev.immunoscape.com/#/notification",
      [map]: "https://cytographer-dev.immunoscape.com/#/notification",
    },
    "us-west-1": {
      [heatplot]: "https://cytographer-dev.immunoscape.com/#/projects",
      [gating]: "https://cytographer-dev.immunoscape.com/#/documentation",
      [generateMeta]: "https://cytographer-dev.immunoscape.com/#/notification",
      [beadNormalization]: "https://cytographer-dev.immunoscape.com/#/help",
      [manualTetdecon2021]: "https://cytographer-dev.immunoscape.com/#/help",
      [debarcode]: "https://cytographer-dev.immunoscape.com/#/notification",
      [scatterPlot]: "https://cytographer-dev.immunoscape.com/#/notification",
      [highdimInteractive]:
        "https://cytographer-dev.immunoscape.com/#/notification",
      [sequencingGating]:
        "https://cytographer-dev.immunoscape.com/#/notification",
      [histogram]: "https://cytographer-dev.immunoscape.com/#/notification",
      [tcrQuery]: "https://cytographer-dev.immunoscape.com/#/notification",
      [stitchr]: "https://cytographer-dev.immunoscape.com/#/notification",
      [curate]: "https://cytographer-dev.immunoscape.com/#/notification",
      [map]: "https://cytographer-dev.immunoscape.com/#/notification",
    },
  },
  dev: {
    "ap-southeast-1": {
      [heatplot]: "https://shiny.immunoscape.com/app_direct/03_heatplotshiny/",
      [gating]: "https://shiny.immunoscape.com/app_direct/02_gating/",
      [generateMeta]: "https://shiny.immunoscape.com/app_direct/05_metadata/",
      [beadNormalization]:
        "https://shiny.immunoscape.com/app_direct/07_beadnorm/",
      [manualTetdecon2021]:
        "https://shiny.immunoscape.com/app_direct/09_manual_tetdecon/",
      [debarcode]: "https://shiny.immunoscape.com/app_direct/05_debarcode/",
      [scatterPlot]: "https://shiny.immunoscape.com/app_direct/11_scatterplot/",
      [highdimInteractive]:
        "https://shiny.immunoscape.com/app_direct/13_highdimshiny/",
      [sequencingGating]:
        "https://shiny.immunoscape.com/app_direct/15_gateseq/",
      [histogram]: "https://shiny.immunoscape.com/app_direct/17_histogram/",
      [tcrQuery]: "https://shiny.immunoscape.com/app_direct/19_tcrquery/",
      [stitchr]: "https://shiny.immunoscape.com/app_direct/21_stitchr/",
      [curate]: "https://shiny.immunoscape.com/app_direct/23_curate/",
      [map]: "https://shiny.immunoscape.com/app_direct/25_map/",

    },
    "us-west-1": {
      [heatplot]:
        "https://shiny.us-west-1.immunoscape.com/app_direct/03_heatplotshiny/",
      [gating]: "https://shiny.us-west-1.immunoscape.com/app_direct/02_gating/",
      [generateMeta]:
        "https://shiny.us-west-1.immunoscape.com/app_direct/05_metadata/",
      [beadNormalization]:
        "https://shiny.immunoscape.com/app_direct/07_beadnorm/",
      [manualTetdecon2021]:
        "https://shiny.immunoscape.com/app_direct/09_manual_tetdecon/",
      [debarcode]: "https://shiny.immunoscape.com/app_direct/05_debarcode/",
      [scatterPlot]: "https://shiny.immunoscape.com/app_direct/11_scatterplot/",
      [highdimInteractive]:
        "https://shiny.immunoscape.com/app_direct/13_highdimshiny/",
      [sequencingGating]:
        "https://shiny.immunoscape.com/app_direct/15_gateseq/",
      [histogram]: "https://shiny.immunoscape.com/app_direct/17_histogram/",
      [tcrQuery]: "https://shiny.immunoscape.com/app_direct/19_tcrquery/",
      [stitchr]: "https://shiny.immunoscape.com/app_direct/21_stitchr/",
      [curate]: "https://shiny.immunoscape.com/app_direct/23_curate/",
      [map]: "https://shiny.immunoscape.com/app_direct/25_map/",
    },
    "eu-north-1": {
      [gating]: "https://shiny.eu-north-1.immunoscape.com/app_direct/02_gating/",
      [heatplot]:
        "https://shiny.eu-north-1.immunoscape.com/app_direct/03_heatplotshiny/",
      [debarcode]: "https://shiny.eu-north-1.immunoscape.com/app_direct/05_debarcode/",
      [beadNormalization]:
        "https://shiny.eu-north-1.immunoscape.com/app_direct/07_beadnorm/",
      [manualTetdecon2021]:
        "https://shiny.eu-north-1.immunoscape.com/app_direct/09_manual_tetdecon/",
      
      [scatterPlot]: "https://shiny.eu-north-1.immunoscape.com/app_direct/11_scatterplot/",
      [highdimInteractive]:
        "https://shiny.eu-north-1.immunoscape.com/app_direct/13_highdimshiny/",
      [sequencingGating]:
        "https://shiny.eu-north-1.immunoscape.com/app_direct/15_gateseq/",
      [histogram]: "https://shiny.eu-north-1.immunoscape.com/app_direct/17_histogram/",
      [tcrQuery]: "https://shiny.eu-north-1.immunoscape.com/app_direct/19_tcrquery/",
      [stitchr]: "https://shiny.eu-north-1.immunoscape.com/app_direct/21_stitchr/",
      [curate]: "https://shiny.eu-north-1.immunoscape.com/app_direct/23_curate/",
      [map]: "https://shiny.eu-north-1.immunoscape.com/app_direct/25_map/",
    },
  },
  production: {
    "ap-southeast-1": {
      [heatplot]: "https://shiny.immunoscape.com/app_direct/04_heatplotshiny/",
      [gating]: "https://shiny.immunoscape.com/app_direct/01_gating/",
      [generateMeta]: "https://shiny.immunoscape.com/app_direct/06_metadata/",
      [beadNormalization]:
        "https://shiny.immunoscape.com/app_direct/08_beadnorm/",
      [manualTetdecon2021]:
        "https://shiny.immunoscape.com/app_direct/10_manual_tetdecon/",
      [debarcode]: "https://shiny.immunoscape.com/app_direct/06_debarcode/",
      [scatterPlot]: "https://shiny.immunoscape.com/app_direct/12_scatterplot/",
      [highdimInteractive]:
        "https://shiny.immunoscape.com/app_direct/14_highdimshiny/",
      [sequencingGating]:
        "https://shiny.immunoscape.com/app_direct/16_gateseq/",
      [histogram]: "https://shiny.immunoscape.com/app_direct/18_histogram/",
      [tcrQuery]: "https://shiny.immunoscape.com/app_direct/20_tcrquery/",
      [stitchr]: "https://shiny.immunoscape.com/app_direct/22_stitchr/",
      [curate]: "https://shiny.immunoscape.com/app_direct/24_curate/",
      [map]: "https://shiny.immunoscape.com/app_direct/26_map/",
    },
    "us-west-1": {
      [heatplot]:
        "https://shiny.us-west-1.immunoscape.com/app_direct/04_heatplotshiny/",
      [gating]: "https://shiny.us-west-1.immunoscape.com/app_direct/01_gating/",
      [generateMeta]:
        "https://shiny.us-west-1.immunoscape.com/app_direct/06_metadata/",
      [beadNormalization]:
        "https://shiny.immunoscape.com/app_direct/08_beadnorm/",
      [manualTetdecon2021]:
        "https://shiny.immunoscape.com/app_direct/10_manual_tetdecon/",
      [debarcode]: "https://shiny.immunoscape.com/app_direct/06_debarcode/",
      [scatterPlot]: "https://shiny.immunoscape.com/app_direct/12_scatterplot/",
      [highdimInteractive]:
        "https://shiny.immunoscape.com/app_direct/14_highdimshiny/",
      [sequencingGating]:
        "https://shiny.immunoscape.com/app_direct/16_gateseq/",
      [histogram]: "https://shiny.immunoscape.com/app_direct/18_histogram/",
      [tcrQuery]: "https://shiny.immunoscape.com/app_direct/20_tcrquery/",
      [stitchr]: "https://shiny.immunoscape.com/app_direct/22_stitchr/",
      [curate]: "https://shiny.immunoscape.com/app_direct/24_curate/",
      [map]: "https://shiny.immunoscape.com/app_direct/26_map/",
    },
    "eu-north-1": {
      [gating]: "https://shiny.eu-north-1.immunoscape.com/app_direct/01_gating/",
      [heatplot]:
        "https://shiny.eu-north-1.immunoscape.com/app_direct/04_heatplotshiny/",
      [debarcode]: "https://shiny.eu-north-1.immunoscape.com/app_direct/06_debarcode/",
      [beadNormalization]:
        "https://shiny.eu-north-1.immunoscape.com/app_direct/08_beadnorm/",
      [manualTetdecon2021]:
        "https://shiny.eu-north-1.immunoscape.com/app_direct/10_manual_tetdecon/",
      [scatterPlot]: "https://shiny.eu-north-1.immunoscape.com/app_direct/12_scatterplot/",
      [highdimInteractive]:
        "https://shiny.eu-north-1.immunoscape.com/app_direct/14_highdimshiny/",
      [sequencingGating]:
        "https://shiny.eu-north-1.immunoscape.com/app_direct/16_gateseq/",
      [histogram]: "https://shiny.eu-north-1.immunoscape.com/app_direct/18_histogram/",
      [tcrQuery]: "https://shiny.eu-north-1.immunoscape.com/app_direct/20_tcrquery/",
      [stitchr]: "https://shiny.eu-north-1.immunoscape.com/app_direct/22_stitchr/",
      [curate]: "https://shiny.eu-north-1.immunoscape.com/app_direct/24_curate/",
      [map]: "https://shiny.eu-north-1.immunoscape.com/app_direct/26_map/",
    },
  },
};

const Shiny = (props: IProps) => {
  const profile: IProfile = useSelector((state: IStore) => state.user.profile);
  const pipelineType: string = useSelector(
    (state: IStore) => state.uiHistory.pipelineType
  );
  const pipeline: IPipeline = useSelector((state: IStore) => state.pipeline);
  const selectedExcution = useSelector(
    (state: IStore) => state.execution.selected_execution.id
  );
  const {
    input,
    shinyInput,
    tabId,
    pipelineId,
    pipelineScript,
    isLifeDeath,
    name,
    description,
    random,
    excludeInputs,
    cloneSource,
    batchId,
    isStandalone,
  } = pipeline;
  const projectId = pipeline.projectId || "";
  const dispatch = useDispatch<TDispatch<TExecution>>();
  const [isRequestOutput, setIsRequestOutput] = useState<boolean>(false);

  const submitBatchPipeline = (
    additionalParams: { [x: string]: any },
    cb?: Function
  ) => {
    const inputClone = _.cloneDeep(input);

    // add params from manual
    for (const adds in additionalParams) {
      if (!_.has(inputClone, adds)) {
        inputClone[adds] = additionalParams[adds];
      }
    }

    // analyse each input item
    const currentParams = _.get(
      pipelineBasicParams,
      `${tabId}.${tetdecon2021}.${pipelineScript}`,
      []
    );
    for (const key in inputClone) {
      const value = inputClone[key];
      if (!isInputValid(key, value, pipelineId as number)) {
        delete inputClone[key];
      }

      // remove params outside pipeline
      if (!_.includes(_.keys(currentParams), key)) {
        delete inputClone[key];
      }
    }

    let newName = `${name}`;
    const suffix = ` (Auto Generated from ${additionalParams.id})`;
    if (newName.length > PIPELINE_NAME_MAX_LENGTH - suffix.length) {
      const more = "...";
      newName = newName.substring(
        0,
        PIPELINE_NAME_MAX_LENGTH - suffix.length - more.length
      );
      newName += more;
    }

    const data: any = {
      projectId: projectId,
      pipelineId: tetdecon2021,
      name: `${newName}${suffix}`,
      description,
      metadata: {
        random: random,
        input: inputClone, // to store the real input data for pipeline cloning,
        isShiny: false,
        tabId: +tabId,
        pipelineScript,
      },
      input: inputClone,
      cloneSource,
    };

    dispatch(runPipeline(data, cb));
  };

  const getShinyOutput = (cb?: Function) => {
    if (pipelineId != tetdecon2021) {
      dispatch(
        getOutput((result: any, refreshPipeline: Function) => {
          // get cutoff files
          if (
            result.data &&
            result.data.pipelineId == manualTetdecon2021 &&
            !_.isEmpty(result.data.cutoff)
          ) {
            submitBatchPipeline(result.data, refreshPipeline);
          } else {
            dispatch(resetPipeline());
            refreshPipeline();
          }
        }, cb)
      );
    }
  };

  const onBack = () => {
    setIsRequestOutput(true);

    if (pipelineType === "preprocessing") {
      dispatch(setSelectedBatch(+batchId, +projectId));
      history.push({ pathname: "/pipeline/preprocessing" });
    } else {
      history.push({ pathname: `/projects/${pipelineType}/${projectId}` });
    }
  };

  const getSelectiveInput = () => {
    const currentParams = _.get(
      pipelineBasicParams,
      `${tabId}.${pipelineId}.${pipelineScript}`,
      []
    );
    const params = _.keys(currentParams);
    if (_.includes(excludeInputs, "transformation")) {
      _.remove(params, (i) => i === "transformation");
    }

    // remove params with value 'all' or empty array
    _.each(Object.keys(shinyInput), (key) => {
      if (shinyInput[key] === "all" || _.isEmpty(shinyInput[key])) {
        delete shinyInput[key];
      }
    });
    const selectedInput = _.pick(shinyInput, params);
    // logic for gating subsetting
    if (!!(pipeline as any).subsetting) {
      selectedInput.subsetting = true;
    }

    // logic for manual tetdecon2021
    if (pipelineId === manualTetdecon2021 && pipeline.cloneSource) {
      selectedInput.cloned = true;
    }

    // logic for life/death gating
    if (pipelineId === gating && isLifeDeath) {
      selectedInput.lifeDeath = true;
    }

    // logic for standalone
    if (pipelineId === gating && isStandalone) {
      selectedInput.standalone = true;
    }

    // logic for curate pipeline
    if (pipelineId === curate) {
      const firstFile = selectedInput.abfiles[0];
      const pathOnly = firstFile.substring(0, firstFile.lastIndexOf('/'));
      selectedInput.abfiles = pathOnly;
    }

    return selectedInput;
  };

  useEffect(() => {
    console.log(input);
    return () => {
      swal({
        title: "Please wait",
        text: "Loading...",
        closeOnClickOutside: false,
        buttons: { visible: false },
      });

      getShinyOutput(() => {
        dispatch(getOneProject(projectId || 0, true));
        if (pipelineType === "preprocessing") {
          dispatch(getOneBatch(+batchId));
        }
        swal.close!();
      });
    };
  }, []);

  const selectedInput = getSelectiveInput();
  //const region = _.get(shinyInput, "region", "ap-southeast-1");
  const region = profile.defaultShinyServerRegion;
  const shinyUrl = _.get(
    shinyUrls,
    `${config.name}.${region}.${pipelineId}`,
    ""
  );

  return (
    <div className="pipeline done">
      <Breadcrumb tag="nav">
        <BreadcrumbItem
          tag="a"
          href={`#/projects/${pipelineType}/${projectId}`}
        >
          Projects
        </BreadcrumbItem>
        <BreadcrumbItem tag="a" href="#/pipeline/form">
          Run Pipeline
        </BreadcrumbItem>
        <BreadcrumbItem active tag="span">
          Shiny
        </BreadcrumbItem>
      </Breadcrumb>

      <div className="shiny">
        <ShinyIframeView params={selectedInput} shinyUrl={shinyUrl} />
      </div>

      <div className="text-right" style={{ margin: "20px 0" }}>
        <button
          className="btn-primary"
          type="button"
          onClick={onBack}
          disabled={isRequestOutput}
        >
          <i className="fa fa-arrow-left"></i> Return to Dashboard
        </button>
      </div>
    </div>
  );
};

export default Shiny;
